import React, { useState } from 'react';
import ReactMapboxGl, { Marker, Popup, Cluster } from 'react-mapbox-gl';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Icon, Flag } from 'semantic-ui-react';

import Layout from '../components/Layout';
import { buildSiteMap } from '../data/helperMethods';
import { coordinates } from '../data/consts';

const POPUP_OFFSET = {
  'bottom-left': [12, -38],  'bottom': [0, -38], 'bottom-right': [-12, -38]
};

const MAP_CONTAINER_STYLE = {
  height: '80vh',
  width: '80vw',
  margin: '0 auto',
};
const MAP_CENTER = [138.2529, 36.2048];
const MAP_ZOOM = [1];

const MyFootstepsTemplate = ({ contentMap }) => {
  const [shouldShowPopup, setPopupVisibility] = useState(false);
  const [shouldZoomOnClusterClick, setZoomOnClusterClick] = useState(false);
  const [popupCoordinates, setPopupCoordinates] = useState([0, 0])
  const [popupContentKey, setPopupContentKey] = useState([]);
  const [popupType, setPopupType] = useState('');

  const handleClusterClick = (coordinates, country) => (event) => {
    console.log('cluster: ', coordinates);
    if (!shouldShowPopup) {
      setPopupVisibility(true);
      setPopupContentKey([country]);
      setZoomOnClusterClick(true);
      setPopupCoordinates(coordinates);
      setPopupType('CLUSTER');
      return;
    }

    setPopupVisibility(false);
    setZoomOnClusterClick(false);
  };

  const handleMarkerClick = (coordinates, city, country) => (event) => {
    console.log('marker: ', coordinates);
    if (!shouldShowPopup) {
      setPopupVisibility(true);
      setPopupContentKey([country, city]);
      setPopupCoordinates(coordinates);
      setPopupType('MARKER');
      return;
    }

    setPopupVisibility(false);
  };

  const handleMapClick = () => {
    setPopupVisibility(false);
    setZoomOnClusterClick(false);
  };

  const renderCluster = (country) => {
    const countryCluster = coordinates[country];

    return (
      <React.Fragment>
        <Cluster ClusterMarkerFactory={ClusterMarker(country)} zoomOnClick={shouldZoomOnClusterClick}>
          {countryCluster.map(ClusterSubMarker(country))}
        </Cluster>
      </React.Fragment>
    );
  };

  const ClusterSubMarker = (country) => ({ latitude, longitude, city }, key) => (
    <Marker
      key={key}
      coordinates={[latitude, longitude]}
      onClick={handleMarkerClick([latitude, longitude], city, country)}
    >
      <Icon name='map marker alternate' size='big' />
    </Marker>
  );

  const ClusterMarker = (country) => (coordinates) => {
    return (
      <Marker coordinates={coordinates} onClick={handleClusterClick(coordinates, country)}>
        <Flag name={country} />
      </Marker>
    );
  };

  const [ country, city ] = popupContentKey;

  const popupMeta = contentMap[country]?.cities[city] || contentMap[country]?.cities || {};

  const blogPosts = popupType === 'CLUSTER'
    ? Object.keys(popupMeta).map(city => popupMeta[city].blogs).flat()
    : popupMeta.blogs;

  const title = popupType === 'CLUSTER' ? contentMap[country].title : popupMeta.title;

  return (
    <div className="about-asia">
      <section className="section section--gradient">
        <Map
          style="mapbox://styles/mapbox/streets-v11"
          containerStyle={MAP_CONTAINER_STYLE}
          zoom={MAP_ZOOM}
          onClick={handleMapClick}
        >
          {Object.keys(coordinates).map(renderCluster)}
          {shouldShowPopup && <Popup
            coordinates={popupCoordinates}
            offset={POPUP_OFFSET}
          >
            <h4 className="h4__tooltip-title">{title || country}</h4>
            <ul>
              {blogPosts
                ? blogPosts.map(({ title, slug }) => (
                    <li><a href={slug}>{title}</a></li>
                  ))
                : <p>Coming soon ...</p>
              }
            </ul>
          </Popup>}
        </Map>
      </section>
    </div>
  )
};

const Map = ReactMapboxGl({
  accessToken: process.env.GATSBY_MAPBOX_ACCESS_TOKEN,
  minZoom: 1,
});

const MyFootstepsPage = ({ data }) => {
  const paths = data?.allMarkdownRemark?.edges;
  const contentMap = buildSiteMap(paths);
  return (
    <Layout>
      <MyFootstepsTemplate contentMap={contentMap} />
    </Layout>
  )
};

MyFootstepsTemplate.propTypes = {
  contentMap: PropTypes.object.isRequired,
};

export const myFootstepsPageQuery = graphql`
  query MyFootsteps {
    allMarkdownRemark(filter: {fields: {slug: {regex: "/blog/"}}}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            tags
            title
          }
        }
      }
    }
  }
`;

export default MyFootstepsPage;
