import { countries, cities } from './consts';

const buildSiteMap = (paths) => {
  const index = paths.reduce((map, path) => {
    const { fields, frontmatter } = path.node;
    const { slug } = fields;
    const { tags, title } = frontmatter;

    const countriesOfPost = tags.filter(tag => countries[tag.replace(' ', '_').toUpperCase()]); // ['china', 'vietnam']
    const citiesOfPost = tags.filter(tag => cities[tag.replace(' ', '_').toUpperCase()]);

    countriesOfPost.forEach(country => {
      const countryMeta = map[country];

      if (!countryMeta) {
        map[country] = {
          title: country,
          cities: {},
        }
      }

      citiesOfPost.forEach(city => {
        const cityMeta = map[country].cities[city];

        if (!cityMeta) {
          map[country].cities[city] = {
            title: city,
            blogs: [],
          }
        }

        map[country].cities[city].blogs.push({ title, slug });
      });
    });

    return map;
  }, {});

  return index;
};

export {
  buildSiteMap,
};
