const countries = {
  CHINA: 'china',
  ENGLAND: 'england',
  JAPAN: 'japan',
  SINGAPORE: 'singapore',
  MALAYSIA: 'malaysia',
  THAILAND: 'thailand',
  TAIWAN: 'taiwan',
  SRI_LANKA: 'sri_lanka',
  ITALY: 'italy',
  FRANCE: 'france',
  VIETNAM: 'vietnam',
};

const cities = {
  TOKYO: 'tokyo',
  LONDON: 'london',
  BEIJING: 'beijing',
  GUILIN: 'guilin',
  HANGZHOU: 'hangzhou',
  SHENZHEN: 'shenzhen',
  HONG_KONG: 'hong_kong',
  SHANGHAI: 'shanghai',
  NANJING: 'nanjing',
  SUZHOU: 'suzhou',
};

const coordinates = {
  [countries.JAPAN]: [
    {
      latitude: 139.7690,
      longitude: 35.6804,
      city: 'tokyo',
      country: countries.JAPAN,
    },
  ],
  [countries.ENGLAND]: [
    {
      latitude: -0.1278,
      longitude: 51.5074,
      city: 'london',
      country: countries.ENGLAND,
    },
  ],
  [countries.CHINA]: [
    {
      latitude: 116.4074,
      longitude: 39.9042,
      city: 'beijing',
      country: countries.CHINA,
    },
    {
      latitude: 110.1800,
      longitude: 25.2345,
      city: 'guilin',
      country: countries.CHINA,
    }
  ]
};

export {
  countries,
  cities,
  coordinates,
};
